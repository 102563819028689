import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage67Avaleht from "./pages/Webpage67Avaleht";
import Webpage68Tooted from "./pages/Webpage68Tooted";
import Webpage69Ajurveeda from "./pages/Webpage69Ajurveeda";
import Webpage70KONTAKT from "./pages/Webpage70KONTAKT";
import Webpage71Konsultatsioon from "./pages/Webpage71Konsultatsioon";
import Webpage72Teraapiad from "./pages/Webpage72Teraapiad";
import Webpage73Toode from "./pages/Webpage73Toode";
import Webpage74Ajurveeda_soovitab from "./pages/Webpage74Ajurveeda_soovitab";
import Webpage75M____gitingimused from "./pages/Webpage75M____gitingimused";
import Webpage76Privaatsuspoliitika from "./pages/Webpage76Privaatsuspoliitika";
import Webpage77Soovitused_kevadeks from "./pages/Webpage77Soovitused_kevadeks";
import Webpage78Soovitused_s__giseks from "./pages/Webpage78Soovitused_s__giseks";
import Webpage79Soovitused_talveks from "./pages/Webpage79Soovitused_talveks";
import Webpage80Maksa from "./pages/Webpage80Maksa";
import Webpage81K__psised from "./pages/Webpage81K__psised";
import Webpage82Ajurveeda_kehat____bid from "./pages/Webpage82Ajurveeda_kehat____bid";
import Webpage83Not_found from "./pages/Webpage83Not_found";

var baseURL = 'https://d1.itprojektid.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiZWIyYTUwOTU1MzhhOTRhMjEzOTZkODQ0MmNlNmFhODRjN2ZlMzY5NDAxNjc5M2UxYWI0MDczNjllZmMwNjA2MDc5N2Y0ZDNiNTNmZmU4M2MiLCJpYXQiOjE3MTk1NTg1MTcuNzA4OTUxLCJuYmYiOjE3MTk1NTg1MTcuNzA4OTYsImV4cCI6MTcxOTY0NDkxNy43MDU4OTgsInN1YiI6IjgxIiwic2NvcGVzIjpbXX0.ZXqnCfCka-ByX6insLIeQdidkt7BUb4bCxxvu68k0UEPbAPYf8y2knMfqsvZGsvpQj6apAt_vsGTAYcm73Nz394Yfn7xqOG2deFeLpzzcBXRJeeV1Mk-9CreQavgbtonxnBASeBm0cEn5Jor07wa6H6GhYNLy4WCl91Jm4Ah_C_ShL7AANG9a7qZzuqw2yd-MtsiCoM64ZLWdmcrsP9iwv1uY0xTg_mqZuzq7AUGrFi-rrBGvglWyWZ0PCDfpdUYl8gjxt29U2MLy62SUqQ9BjlCE1WuMRVbPh4DrOU5hkoTMKvmyNpipYQOQ2uRnFQo--RMJLiCakVjbzQwaFtR46NnVWSIh1YLD2CwFOUdokf3EZ8f5i9Ii0Kn_HgGX2KHpVhcEu0B2MD_DEh_SBsYLuyEyTKKGoDWzPo5avSh4JuNco751nBPPjsv3d9_6deA3Wd23AjlvqPE8avtqZT1AQ2QVpk5VVhL0nUBIB4OK1TJ7DvUEG6j-NBEEXdRVZdU5LU0kK8aYhRBFj_XLJPavDQhpBcgnHeQl7kPOCYrQ2tkszr9Ug4fQeOdfDyqQtZ9yQTTvsO1BihHgyaJwMM3olUvkB5uXSkrPc3tw0YJPCieDMYioInm64cMcOOa141Y7HS9-v0yE_st1Jj32uhkCXmAMwOck2XFrdf5H92HmTU',
    'Access-api-key': "eyJpdiI6IjNWcU0rT2ZkUlNtRnhLMVRUWDQrd2c9PSIsInZhbHVlIjoiYVRLNk1kQjhleUNxYkYxMlBsd0RjZz09IiwibWFjIjoiZGY5Y2Y3NGEyMzE4YTViYmJiZTE5MDVkZjA0ZDQyM2RlYTFkODFiOGZiMTY0NWI5OTIwNjgyM2Q0NDc5OWVkOCIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6InYyTkYyblA5d2FCZFdweHVsYzFBYVE9PSIsInZhbHVlIjoiTWRzdXFWTkNFWTFOYkh2QUdUVVNrZz09IiwibWFjIjoiYjVlZDkwNDQ3NmIzM2Q5MDJlY2E5NTFiYjkyNWY1ZmU3YzY1YTgwNTNiOGYyMzZhYWU3NTdmNjk5MTE2ZWQwNyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "G-FQF99VNEVF"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage67Avaleht />} exact />;
                                                                    <Route path="/en/tooted" element={<Webpage68Tooted />} exact />;
                                                                    <Route path="/en/ayurveda" element={<Webpage69Ajurveeda />} exact />;
                                                                    <Route path="/en/contact" element={<Webpage70KONTAKT />} exact />;
                                                                    <Route path="/en/consultation" element={<Webpage71Konsultatsioon />} exact />;
                                                                    <Route path="/en/therapy" element={<Webpage72Teraapiad />} exact />;
                                                                    <Route path="/en/toode/:id" element={<Webpage73Toode />} exact />;
                                                                    <Route path="/en/ayurveda/recommend" element={<Webpage74Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/en/terms" element={<Webpage75M____gitingimused />} exact />;
                                                                    <Route path="/en/privacy" element={<Webpage76Privaatsuspoliitika />} exact />;
                                                                    <Route path="/en/ayurveda/seasonalSpring" element={<Webpage77Soovitused_kevadeks />} exact />;
                                                                    <Route path="/en/ayurveda/seasonalAutumn" element={<Webpage78Soovitused_s__giseks />} exact />;
                                                                    <Route path="/en/ayurveda/seasonalWinter" element={<Webpage79Soovitused_talveks />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage80Maksa />} exact />;
                                                                    <Route path="/en/cookiePolicy" element={<Webpage81K__psised />} exact />;
                                                                    <Route path="/en/ayurveda/bodytypes" element={<Webpage82Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/en/*" element={<Webpage83Not_found />} exact />;
                                                    <Route path="*" element={<Webpage83Not_found />} exact />;
                                                                                        <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}